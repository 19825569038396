@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./Component/assets/gilroy-bold/Gilroy-Bold.ttf') format('truetype'); /* or .otf for OpenType format */
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Gilroy-Bold';
}

p,
div,
button,
a,
li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 24px;
  padding-left: 0px;
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.WhyChooseUs .left-image img {
  animation: moveUpDown 3s ease-in-out infinite;
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/* .HeroSection .left-content {
  opacity: 0;
  transform: translateX(-50%);
  transition: transform 1s ease, opacity 1s ease;
}

.HeroSection .left-content.animate {
  animation: slideInLeft 1s forwards;
} */
.WhyChooseUs .left-image {
  opacity: 0;
  transform: translateX(-50%);
  transition: transform 1s ease, opacity 1s ease;
}

.WhyChooseUs .right-content {
  opacity: 0;
  transform: translateX(50%);
  transition: transform 1s ease, opacity 1s ease;
}

.WhyChooseUs .left-image.animate {
  animation: slideInLeft 1s forwards;
}

.WhyChooseUs .right-content.animate{
  animation: slideInRight 1s forwards;
}

.card {
  opacity: 0;
  transform: translateX(-50%);
  transition: transform 1s ease, opacity 1s ease;
}

.card.animate {
  animation: slideInLeft 1s forwards;
}

.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 35px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #51b55b;
  background-color: #51b55b !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}

.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  /* width: 65%; */
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#51b55b, #51b55b);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #51b55b;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Footer1 img {
  margin-top: 40px;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.HeroSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 8%;
  padding-right: 0%;
  padding-bottom: 0%;
  background-color: rgba(33, 99, 166, 1);
  min-height: 90vh;
}


.left-content {
  flex: 1;
}

.heading1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.color1 {
  color: white;
  font-weight: 500;
}
.WhyChooseUs ul li img{
  width: 14px;
  height: 14px;
}
.WhyChooseUs ul li p{
  display: flex;
  align-items: center;
  gap: 10px;
}
.color2 {
  color: #51b55b;
  font-weight: 900;
}

.color3 {
  color: white;
  font-weight: 900;
}

.subheading {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.right-slider {
  flex: 1 1;
  max-width: 50%;
  height: 100%;
}

.slick-slide img {
  width: 100%;
  height: auto;
}

.WhyChooseUs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 8%;
  background-color: #f5f5f5;
}

.left-image {
  flex: 1;
  max-width: 50%;
}

.left-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.right-content {
  flex: 1;
  max-width: 50%;
  padding-left: 20px;
}

.subheading {
  font-size: 1.5em;
  color: #888;
  margin-bottom: 10px;
}

.heading {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.features-list {
  margin-bottom: 20px;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.5em;
  color: #fff;
  margin-right: 15px;
}

.text-content h3 {
  margin: 0;
  font-size: 1.2em;
}

.text-content p {
  margin: 0;
  color: #666;
}
.se {
  padding: 2% 8%;
}
.Services {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  flex-direction: column;
}
.se {
  background-color: #2163a6;
}

.Services .left-content {
  flex: 1;
  max-width: 100%;
  width: 100%;
  text-align: center;
}
.HeroSection .subheading {
  color: white;
}
.subheading {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #2163a6;
  font-weight: 900;
}

.heading {
  font-size: 2.5em;
  margin: 0;
  margin-bottom: 20px;
  color: #51b55b;
}
.Services .subheading {
  color: white;
  font-weight: 800;
}

.subtext {
  font-size: 1.2em;
  color: #666;
}

.right-cards {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.se .btn-group {
  justify-content: center;
}
.card {
  padding: 20px;
  border-radius: 8px;
  background-color: #51b55b;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease;
  position: relative;
  top: 0;
}

.card-0 {
  top: 0;
}

.card-1 {
  top: 0px;
}

.card-2 {
  top: 0;
}

.card-3 {
  top: 0px;
}
.card {
  cursor: pointer;
}
.card:hover {
  background-color: #2163a6;
  transition: 0.5s ease;
  border: 1px solid #51b55b;
}
.card:hover .icon {
  background-color: white;
}
.icon {
  font-size: 2em;
  margin-bottom: 10px;
  background-color: #2163a6;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
}

.card-content h3 {
  margin: 0;
  font-size: 1.4em;
  color: white;
  margin-bottom: 8px;
}

.card-content p {
  margin: 0;
  color: white;
}
.Contact {
  position: relative;
  background-image: url("./Component/assets/images/photos/slide/call to action.webp");
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2163a687; /* Black overlay with 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.big-heading {
  color: white;
  font-size: 42px;
  text-align: center;
  z-index: 1;
}
.ct_cont {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.elementor-widget-container {
  padding: 0% 8%;
}
.elementor-widget-container p {
  line-height: 22px;
}
.elementor section:first-of-type {
  background-color: #2163a6;
  padding: 10% 0%;
  color: white;
  padding-bottom: 5%;
}
.elementor section:first-of-type p a strong {
  color: white !important;
}
.a {
  text-decoration: none;
  color: white;
}
.elementor p strong {
  color: #51b55b;
}
.elementor h2 {
  color: #51b55b;
}
.elementor h4 {
  color: #51b55b;
}
.elementor p b {
  color: #51b55b;
}
.elementor li strong {
  color: #51b55b;
}
.elementor p a {
  text-decoration: none;
  color: #51b55b;
}
.HowWorks {
  padding: 2% 8%;
  background-color: #51b55b;
}
.HowWorks h2 {
  color: white;
}
.HowWorks p {
  color: white;
}
.se .btn-group{
  margin-top: 30px;
}
.icon img{
  width: 100%;
}
.WhyChooseUs ul{
  margin: 0;
  padding: 0;
  margin-left: 35px;
  list-style-type: none;
}

@media (max-width: 768px) {
  .Navbar {
    display: none;
  }
.heading1 span{
  display: block;
}
.heading1 br{
  display: none;
}
.Contact br{
  display: none;
}
  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }

  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #2163a6;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #51b55b; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #51b55b; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 5s; /* Duration of the shine animation */
  }

  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 90%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }

  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }

  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }

  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .HeroSection {
    flex-direction: column;
    padding-right: 8%;
    padding-top: 4%;
  }
  .HeroSection .btn-group {
    display: none;
  }
  .HeroSection .right-slider {
    width: 100%;
    max-width: 100%;
  }
  .heading1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .HeroSection .left-content {
    text-align: center;
    margin-bottom: 20px;
  }
  .WhyChooseUs {
    flex-direction: column-reverse;
  }
  .WhyChooseUs .left-image {
    flex: 1 1;
    max-width: 100%;
  }
  .WhyChooseUs .right-content {
    flex: 1 1;
    max-width: 100%;
    padding-left: 0px;
  }
  .heading {
    font-size: 2em;
    line-height: 1.2em;
  }
  .right-cards {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  .card-0 , .card-1 , .card-2 , .card-3{
    top: 0;
  }
  .se {
    padding: 2% 8%;
  }
  .big-heading{
    font-size: 2em;
  }
  .ct_cont{
    padding: 0% 8%;
  }
}
